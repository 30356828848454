import React from 'react';
import styled from 'styled-components';
import logo from "../../img/logo1.png";
import { BsTwitter, BsLinkedin,BsYoutube } from 'react-icons/bs';
import { SiFiverr, SiGithub } from 'react-icons/si';
import { FaBookReader } from 'react-icons/fa';

const Color = {
  primary: '#163629',
};

const Container = styled.div`
  background: ${Color.primary};
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  height: 100%;
`;

const Logo = styled.img`
  object-fit: cover;
`;

const BoxImg = styled.div`
  width: 400px;
  height: 200px;
  display: flex;
  justify-content: center;
`;

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.a`
  width: 45px;
  height: 45px;
  background: #79A090;
  opacity: 0.7;
  border-radius: 7px;
  margin: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  border: 0;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  & svg {
    background: transparent;
    width: 25px;
  }
`;

const IconsContainer = styled.div`
  display: flex;
  aling-items: start;
  margin-bottom: 20px;
  height: 23%;
`;


const Landing = () => {
  return (
    <Container>
      <Box>
        <BoxImg>
          <Logo src={logo} alt="logo" />
        </BoxImg>
      </Box>
      <IconsContainer>
        <Icon href='https://twitter.com/FellowStack' target='_blank'>
          <BsTwitter />
        </Icon>
        <Icon href='https://www.linkedin.com/company/fellow-stack' target='_blank'>
          <BsLinkedin />
        </Icon>
        <Icon href='https://www.youtube.com/channel/UCrvbVCt-QMGJWWJEefJuxKw' target='_blank'>
          <BsYoutube />
        </Icon>
        <Icon href='https://www.fiverr.com/fellowstack' target='_blank'>
          <SiFiverr />
        </Icon>
        <Icon href='https://github.com/fellowstack?tab=repositories' target='_blank'>
          <SiGithub />
        </Icon>
        <Icon>
          <FaBookReader />
        </Icon>
      </IconsContainer>
    </Container>
  )
}

export default Landing