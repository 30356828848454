import React from 'react'
import { Landing } from '../Screen';
import './App.css';

function App() {
  return (
    <>
      <Landing />
    </>
  )
}

export default App